// extracted by mini-css-extract-plugin
export var about_main = "about-module--about_main--VvsEd";
export var about_header = "about-module--about_header--IeNGi";
export var about_content = "about-module--about_content--1Mf0P";
export var self_portrait_desktop = "about-module--self_portrait_desktop--LJ58Z";
export var self_portrait_mobile = "about-module--self_portrait_mobile--sDrdV";
export var skills_content = "about-module--skills_content--JYhIQ";
export var skills_mobile = "about-module--skills_mobile--ucrKq";
export var orange_separator = "about-module--orange_separator--Oyjxp";
export var picture_frame_mobile = "about-module--picture_frame_mobile---SU2G";
export var skills_desktop = "about-module--skills_desktop--0Dmye";